import React from 'react';

import {
  Box,
  BoxProps,
} from '@waglabs/box-next';
import { Typography } from '@waglabs/typography';
import { Flex } from '@waglabs/flex-next';
import { Image } from '@waglabs/image-next';
import { Container } from '@waglabs/container';

import { Executive } from '../../../../components/Executive';
import { ExecutiveUrls } from '../../../../constants';

import adamStormImg from '../../../../assets/executives/adam-storm.png';
import alecDavidianImg from '../../../../assets/executives/alec-davidian.png';
import alexanderJeremiahImg from '../../../../assets/executives/alexander-jeremiah.png';
import andrewBreksaImg from '../../../../assets/executives/andrew-breksa.png';
import benWilliamsonImg from '../../../../assets/executives/ben-williamson.png';
import brianYeeImg from '../../../../assets/executives/brian-yee.png';
import carliAuranImg from '../../../../assets/executives/carli-auran.png';
import chasSchleyImg from '../../../../assets/executives/chas-schley.png';
import chrisRempelImg from '../../../../assets/executives/christopher-rempel.png';
import davidCaneImg from '../../../../assets/executives/david-cane.png';
import draganaMijalkovicImg from '../../../../assets/executives/dragana-mijalkovic.png';
import drGeorgiaJeremiahImg from '../../../../assets/executives/dr-georgia-jeremiah.png';
import dylanAllreadImg from '../../../../assets/executives/dylan-allread.png';
import garrettSmallwoodImg from '../../../../assets/executives/garrett-smallwood.png';
import jocelynManganImg from '../../../../assets/executives/jocelyn-mangan.png';
import josephSchrickerImg from '../../../../assets/executives/joseph-schricker.png';
import keithMosleyImg from '../../../../assets/executives/keith-mosley.png';
import kimberlyBlackwellImg from '../../../../assets/executives/kimberly-blackwell.png';
import lindseyCanfieldImg from '../../../../assets/executives/lindsey-canfield.png';
import maziArjomandImg from '../../../../assets/executives/mazi-arjomand.png';
import melindaChelliahImg from '../../../../assets/executives/melinda-chelliah.png';
import motleyImg from '../../../../assets/executives/motley.png';
import nicholasYuImg from '../../../../assets/executives/nicholas-yu.png';
import ollieImg from '../../../../assets/executives/ollie.png';
import patrickMccarthyImg from '../../../../assets/executives/patrick-mccarthy.png';
import rachelSpragueImg from '../../../../assets/executives/rachel-sprague.png';
import richardDiBonaImg from '../../../../assets/executives/richard-dibona.png';
import rogerLeeImg from '../../../../assets/executives/roger-lee.png';
import sheilaMarceloImg from '../../../../assets/executives/sheila-marcelo.png';
import winMinImg from '../../../../assets/executives/win-min.png';
import jasonKimImg from '../../../../assets/executives/jason-kim.png';
import kimberlyHollingerImg from '../../../../assets/executives/kimberly-hollinger.png';
import elisaMuntoniImg from '../../../../assets/executives/elisa-muntoni.png';
import marianaAlvesImg from '../../../../assets/executives/mariana-alves.png';

import pawBg from '../../../../assets/paws-bg.svg';

const boardMembers = [
  {
    img: garrettSmallwoodImg,
    name: 'Garrett Smallwood',
    position: 'Board of Directors',
    href: ExecutiveUrls.Garrett,
  },
  {
    img: melindaChelliahImg,
    name: 'Melinda Chelliah',
    position: 'Board of Directors',
    href: ExecutiveUrls.Melinda,
  },
  {
    img: sheilaMarceloImg,
    name: 'Sheila Marcelo',
    position: 'Board of Directors',
    href: ExecutiveUrls.Sheila,
  },
  {
    img: kimberlyBlackwellImg,
    name: 'Kimberly Blackwell',
    position: 'Board of Directors',
    href: ExecutiveUrls.Kimberly,
  },
  {
    img: jocelynManganImg,
    name: 'Jocelyn Mangan',
    position: 'Board of Directors',
    href: ExecutiveUrls.Jocelyn,
  },
  {
    img: rogerLeeImg,
    name: 'Roger Lee',
    position: 'Board of Directors',
    href: ExecutiveUrls.Roger,
  },
  {
    img: brianYeeImg,
    name: 'Brian Yee',
    position: 'Board of Directors',
    href: ExecutiveUrls.Brian,
  },
] as const;

const leadershipMembers = [
  {
    img: garrettSmallwoodImg,
    name: 'Garrett Smallwood',
    position: 'Chief Executive Officer',
    href: ExecutiveUrls.Garrett,
  },
  {
    img: adamStormImg,
    name: 'Adam Storm',
    position: 'President & Chief Product Officer',
    href: ExecutiveUrls.Adam,
  },
  {
    img: davidCaneImg,
    name: 'David Cane',
    position: 'Chief Customer Officer',
    href: ExecutiveUrls.David,
  },
  {
    img: patrickMccarthyImg,
    name: 'Patrick McCarthy',
    position: 'Chief Marketing Officer',
    href: ExecutiveUrls.Patrick,
  },
  {
    img: dylanAllreadImg,
    name: 'Dylan Allread',
    position: 'Chief Operating Officer',
    href: ExecutiveUrls.Dylan,
  },
  {
    img: alecDavidianImg,
    name: 'Alec Davidian',
    position: 'Chief Financial Officer',
    href: ExecutiveUrls.Alec,
  },
  {
    img: nicholasYuImg,
    name: 'Nicholas Yu',
    position: 'Director of Legal',
    href: ExecutiveUrls.Nicholas,
  },
  {
    img: maziArjomandImg,
    name: 'Mazi Arjomand',
    position: 'Chief Technology Officer',
    href: ExecutiveUrls.Mazi,
  },
  {
    img: carliAuranImg,
    name: 'Carli Auran',
    position: 'User Acquisition Leader',
    href: ExecutiveUrls.Carli,
  },
  {
    img: motleyImg,
    name: 'Mötley',
    position: 'Influencer-in-Residence',
    href: '#',
  },
  {
    img: draganaMijalkovicImg,
    name: 'Dragana Mijalkovic',
    position: 'Design Leader',
    href: ExecutiveUrls.Dragana,
  },
  {
    img: lindseyCanfieldImg,
    name: 'Lindsey Canfield',
    position: 'People Ops Leader',
    href: ExecutiveUrls.Lindsey,
  },
  {
    img: josephSchrickerImg,
    name: 'Joseph Schricker',
    position: 'Growth Product Leader',
    href: ExecutiveUrls.Joseph,
  },
  {
    img: keithMosleyImg,
    name: 'Keith Mosley',
    position: 'GM of Supply',
    href: ExecutiveUrls.Keith,
  },
  {
    img: richardDiBonaImg,
    name: 'Richard DiBona',
    position: 'Customer Success Leader',
    href: ExecutiveUrls.Richard,
  },
  {
    img: chasSchleyImg,
    name: 'Chas Schley',
    position: 'DevOps Leader',
    href: ExecutiveUrls.Chas,
  },
  {
    img: winMinImg,
    name: 'Win Min',
    position: 'Mobile Leader',
    href: ExecutiveUrls.Win,
  },
  {
    img: chrisRempelImg,
    name: 'Christopher Rempel',
    position: 'API Leader',
    href: ExecutiveUrls.Chris,
  },
  {
    img: andrewBreksaImg,
    name: 'Andrew Breksa',
    position: 'Software Architect',
    href: ExecutiveUrls.Andrew,
  },
  {
    img: rachelSpragueImg,
    name: 'Rachel Sprague',
    position: 'Data Analytics Leader',
    href: ExecutiveUrls.Rachel,
  },
  {
    img: drGeorgiaJeremiahImg,
    name: 'Dr. Georgia Jeremiah',
    position: 'Furmacy Co-Founder & Veterinarian',
    href: ExecutiveUrls.DrGeorgiaJeremiah,
  },
  {
    img: alexanderJeremiahImg,
    name: 'Alexander Jeremiah',
    position: 'Furmacy Co-Founder & CTO',
    href: ExecutiveUrls.AlexanderJeremiah,
  },
  {
    img: benWilliamsonImg,
    name: 'Ben Williamson',
    position: 'Sr. Program Manager, Trust & Safety',
    href: ExecutiveUrls.BenWilliamson,
  },
  {
    img: marianaAlvesImg,
    name: 'Mariana',
    position: 'Brand Leader, Maxbone',
    href: ExecutiveUrls.Mariana,
  },
  {
    img: elisaMuntoniImg,
    name: 'Elisia',
    position: 'Wholesale Leader',
    href: ExecutiveUrls.Elisa,
  },
  {
    img: kimberlyHollingerImg,
    name: 'Kimberly Hollinger',
    position: 'Assistant Controller',
    href: ExecutiveUrls.KimberlyHollinger,
  },
  {
    img: jasonKimImg,
    name: 'Jason',
    position: 'SEC Reporting and Technical Accounting Leader',
    href: ExecutiveUrls.Jason,
  },
  {
    img: ollieImg,
    name: 'Ollie',
    position: 'Chief Barketing Officer',
    href: ExecutiveUrls.Ollie,
  },
] as const;

export type ExecutiveSectionProps = BoxProps;
export const ExecutiveSection = (props: ExecutiveSectionProps) => (
  <Box
    id="team"
    position="relative"
    mb={['80px', '40px']}
    {...props}
  >
    <Container>
      <Typography
        variant="h2"
        color="#474747"
        textAlign="center"
        maxWidth="1000px"
        mx="auto"
        my={['40px', '24px']}
      >
        {'Wag! is designed and backed by the world\'s leading marketplace experts'}
      </Typography>
      <Box
        mt={6}
      >
        <Typography
          variant="h3"
          color="wagBlue"
          mb={0}
          textAlign="center"
          textTransform="uppercase"
        >
          Board of Directors
        </Typography>
        <Flex
          justifyContent={['unset', 'center']}
          flexWrap="wrap"
          m="0 auto"
          mt={[4, null, 0]}
          px={[0, '24px', '24px', '54px']}
        >
          {boardMembers.map((boardMember, index) => (
            <Executive
              alt={boardMember.name}
              href={boardMember.href}
              index={index}
              key={boardMember.name}
              name={boardMember.name}
              src={boardMember.img}
              position={boardMember.position}
            />
          ))}
        </Flex>
      </Box>
      <Box
        pt={3}
      >
        <Typography
          variant="h3"
          color="wagBlue"
          m={0}
          textAlign="center"
          textTransform="uppercase"
        >
          Leadership
        </Typography>
        <Flex
          justifyContent={['unset', 'center']}
          flexWrap="wrap"
          m="0 auto"
          mt={[4, null, 0]}
          px={[0, '24px', '24px', '54px']}
        >
          {leadershipMembers.map((leader, index) => (
            <Executive
              alt={leader.name}
              href={leader.href}
              index={index}
              key={leader.name}
              name={leader.name}
              src={leader.img}
              position={leader.position}
            />
          ))}
        </Flex>
      </Box>
    </Container>
    <Image
      src={pawBg}
      width="504px"
      alt="paw background"
      display={['none', 'block']}
      position="absolute"
      left="-200px"
      bottom={0}
    />
  </Box>
);
